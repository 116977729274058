import { useEffect, useState } from 'react';
import { AnalyticsService } from '../../../core/services';
import AnalyticsDashboard from './components/analytics-dashboard';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Skeleton, Tab } from '@mui/material';
import { MostEngagingPosts } from './components/most-engaging-posts';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { BrandDashboardService } from '../../../core/services/dashboard-brand.service';
import { AdminDashboardService } from '../../../core/services/admin-dashboard.service';
import { MostEngagingInfluencer } from './components/most-engaging-influencer';
import DetailedInsightsInfluencer from './components/detailed-insights-influencer';
import DetailedInsightsPost from './components/detailed-insights-post';
import ContentLoading from '../../../components/loader/loading';
import { useNavigate } from 'react-router-dom';
import { SortingColumnsType } from '../../../components/table-component/table';
import { FormValidation } from '../../../core/utility';
import SampleDetailedAnalytics from './components/sample-detailed-analytics';
import { sampleMostEngagingInfluencers, sampleInfluencerDetailedData, samplePostDetailedData, sampleMostEngagingPosts } from '../../../data/data';
import YouTubeEmbed from '../../../components/instagram-embed/youtube-embed';
const analyticsService = new AnalyticsService();
const adminDashboardService = new AdminDashboardService();

interface Props {
  UserReducer: any;
  UserID: any;
  loading: (data: any) => void;
  selectCampaign: any;
  getSubscriptionDetail:any;
}


interface ErrorTypes {
  status: boolean;
  message: string;
}

const columnDisplayNamePostDetailedData = {
  er: "ER",
  delete: "",
  video_views: "Views",
  likes: "Likes",
  comments: "Comments",
  created_at: "Date"

};
const columnDisplayNameInfluencerDetailedData = {
  total_post: "Post",
  total_er: "ER",
  total_views: "Views",
  like_count: "Likes",
  total_comments: "Comments"

};

const PostDetailedData: SortingColumnsType[] = [
  {
    name: "",
    columnSize: "45%",
  },
  {
    name: "ER",
    columnSize: "10%",
  },
  {
    name: "Views",
    columnSize: "10%",
  },
  {
    name: "Likes",
    columnSize: "10%",
  },
  {
    name: "Comments",
    columnSize: "10%",
  },
  {
    name: "Posted on",
    columnSize: "15%",
  },
];

const InfluencerDetailedData: SortingColumnsType[] = [

  {
    name: "",
    columnSize: "50%",
  },
  {
    name: "Post",
    columnSize: "10%",
  },
  {
    name: "ER",
    columnSize: "10%",
  },
  {
    name: "Views",
    columnSize: "10%",
  },
  {
    name: "Likes",
    columnSize: "10%",
  },
  {
    name: "Comments",
    columnSize: "10%",
  },

];



export const ShareAnalyticsYouTube = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const urlPathname = window.location.pathname;
  const formValidation = new FormValidation();
  const campaignPlatformType = urlParams.get("platform");
  const campaignToken = urlParams.get("accessKey");
  const hasShareAnalytics = urlPathname.includes('share-analytics') || urlPathname.includes('share-analytics-yt');
  const data = { campaignToken, campaignPlatformType };
  const navigate = useNavigate()
  const [selectedCampaign, setSelectedCampaign] = useState<any>({});
  const [selectedCampaignTitle, setSelectedCampaignTitle] = useState<any>("");
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState("1");
  const [value2, setValue2] = useState("1");
  const [value4, setValue4] = useState("1");
  const [analyticsCumulativeDataYtNumericData, setanalyticsCumulativeDataYtNumericData] = useState<any>([]);
  const [analyticsCumulativeDataYtMediaData, setAnalyticsCumulativeDataYtMediaData] = useState<any>([]);
  const [postFilterData, setPostFilterData] = useState<any>([]);
  const [influencerFilterData, setInfluencerFilterData] = useState<any>([]);
  const [graphData, setGraphData] = useState<any>([]);
  const [campaignId, setCampaignId] = useState(0)
  const [campaignPlatform, setCampaignPlatform] = useState("")
  const [totalEr, setTotalEr] = useState(0)
  const [smId, setSmId] = useState(0)
  const [postAnalyticsEr, setPostAnalyticsEr] = useState<any>([])
  const [influencerAnalyticsEr, setInfluencerAnalyticsEr] = useState<any>([])
  const [totalCost, setTotalCost] = useState(0);
  const [totalPost, setTotalPost] = useState(0);
  const [openUpgradeBrandModal, setOpenUpgradeBrandModal] = useState(false);
  const [openModalROI, setOpenModalROI] = useState<boolean>(false);
  const [addedLinksDetails, setAddedLinksDetails] = useState<any>([]);
  const [buttonClass, setButtonClass] = useState<string>('btn btn-primary disabled');
  const [openModal, setOpenModal] = useState(false);
const getPostLinkData = async (campaignId:any) => {
      const res = await analyticsService.getAnalyticsLinks(campaignId);
      if (res.data.status) {
        setAddedLinksDetails(res.data.rows);
      }
    }
useEffect(() => {
    const verifiedUser = async () => {
        setLoading(true); // Set loading to true when starting data fetch

        try {
            const res = await analyticsService.verifySharedUser(data);

            if (res.status === 1) { // Ensure the status indicates a valid token response
                let response;
                if (campaignPlatformType === 'Youtube') {
                    response = await analyticsService.shareBrandAnalyticsYoutube({ 
                        campaign_id: res.data?.rows[0]?.campaign_id, 
                        sm_id: null 
                    });

                    if (response?.data?.status) {
                      setSelectedCampaign(response?.data?.campaign_details);
                      setSelectedCampaignTitle(response?.data?.campaignTitle);
                      getPostLinkData(res.data?.rows[0]?.campaign_id)
                      
                      setAnalyticsCumulativeDataYtMediaData(response?.data?.analytics_data_yt_media[0])
                      if(response?.data?.analytics_data_yt_numeric.length > 0){
                        setanalyticsCumulativeDataYtNumericData(response?.data?.analytics_data_yt_numeric[0])
                      }
                      setPostFilterData(response?.data?.post_data_content)
                      setInfluencerFilterData(response?.data?.influencer_data_content)
                      setGraphData(response?.data?.graph_data_array)
                      setTotalEr(response?.data?.total_er)
                      setPostAnalyticsEr(response?.data?.yt_post_analytic_er)
                      setInfluencerAnalyticsEr(response?.data?.yt_influencer_analytic_er)
                      if(response?.data?.total_posts > 0){
                        setTotalPost(response?.data?.total_posts)
                      }
                    }
                } 
            } else {
                // If the token is invalid, log the error and avoid the redirect
                console.error('Invalid or expired token:', res.info);
                // Optionally show an error message on the page instead of redirecting to login
            }
        } catch (error) {
            console.error('Error occurred during user verification or data fetch:', error);
            // Handle any other errors (network issues, etc.)
        } finally {
            setLoading(false); // Set loading to false after the process completes (whether successful or not)
        }
    };

    verifiedUser();
}, [campaignToken, campaignPlatformType]);



  const likesOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Likes Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData.map((entry: any) => entry.post_date),
    },
    yAxis: {
      title: {
        text: 'Likes',
      },
    },
    series: [
      {
        name: 'Date',
        data: graphData.map((entry: any) => entry.likes),
      },
    ],
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            //'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            //'downloadSVG',
            'downloadCSV',
            'downloadXLS',
          ]
        }
      }
    },
  };

  const playsOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Plays Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData.map((entry: any) => entry.post_date),
    },
    yAxis: {
      title: {
        text: 'Total Views',
      },
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    series: [
      {
        name: 'Total Views',
        data: graphData.map((entry: any) => entry.views),
      },
    ],
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            //'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            //'downloadSVG',
            'downloadCSV',
            'downloadXLS',
          ]
        }
      }
    },
  };
  const commentOptions = {
    chart: {
      type: 'line',
    },
    title: {
      text: 'Comments Over Time',
    },
    xAxis: {
      type: 'datetime',
      categories: graphData.map((entry: any) => entry.post_date),
    },
    yAxis: {
      title: {
        text: 'Total Comments',
      },
    },
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    series: [
      {
        name: 'Total Comments',
        data: graphData.map((entry: any) => entry.comments),
      },
    ],
    exporting: {
      enabled: false,
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            //'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            //'downloadSVG',
            'downloadCSV',
            'downloadXLS',
          ]
        }
      }
    },
  };
    // -----------
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleChange2 = (event: React.SyntheticEvent, newValue: string) => {
    setValue2(newValue);
  };

  const handleChange4 = (event: React.SyntheticEvent, newValue: string) => {
    setValue4(newValue);
  };
  const columnDisplayNamePostDetailedData = {
  er: "ER",
  video_plays: "Plays",
  likes: "Likes",
  comments: "Comments",
  created_at: "Date"

};
const columnDisplayNameInfluencerDetailedData = {
  total_post: "Post",
  total_er: "ER",
  video_plays: "Plays",
  total_likes: "Likes",
  total_comments: "Comments"

};

const PostDetailedData: SortingColumnsType[] = [
  {
    name: "Post",
    columnSize: "35%",
  },
    ...(!hasShareAnalytics
  ? [
      {
        name: "",
        columnSize: "10%",
      },
    ]
  : []),
  {
    name: "ER",
    columnSize: "14%",
  },
  {
    name: "Views",
    columnSize: "10%",
  },
  {
    name: "Likes",
    columnSize: "10%",
  },
  {
    name: "Comments",
    columnSize: "10%",
  },
  {
    name: "Posted on",
    columnSize: "15%",
  },
];

const InfluencerDetailedData: SortingColumnsType[] = [

  {
    name: "Influencer",
    columnSize: "35%",
  },
  {
    name: "Post",
    columnSize: "10%",
  },
  {
    name: "ER",
    columnSize: "14%",
  },
  {
    name: "Views",
    columnSize: "10%",
  },
  {
    name: "Likes",
    columnSize: "10%",
  },
  {
    name: "Comments",
    columnSize: "10%",
  },

];

    return <>{loading ? (
        <div className='d-flex align-items-center justify-content-center' style={{height:'calc(100vh - 250px)'}}>
            <ContentLoading width='150' height='150' /> 
            </div>
        ) : ( <div className="share-analytics brand-analytics detailed-analytics">
        <div className="detailed-analytics-content">
          <div className="container custom-container">
            <div className=''>
              {selectedCampaignTitle && <strong className='fs-20 pb-4 d-block'>{selectedCampaignTitle}</strong>}
            </div>
            <div className="row project-detail-sec">
              <div className="col-md-12">
                <div className="upper-grid">
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>

                      <AnalyticsDashboard postFilterData={postFilterData} analyticsCumulativeDataMediaData={analyticsCumulativeDataYtMediaData} campaign_platform={campaignPlatformType} analyticsCumulativeDataNumericData={analyticsCumulativeDataYtNumericData} totalCost={totalCost} totalPost={totalPost} />
                    </Grid>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='container custom-container'>
          {postFilterData.length === 0 && addedLinksDetails.length === 0 && <>
          
          <strong className="pb-3 d-block">Sample analytics report <span className="fs-12">(Once the links are added, the analytics will be visible as shown below)</span></strong>
          <SampleDetailedAnalytics platform={campaignPlatformType} sampleMostEngagingInfluencers={sampleMostEngagingInfluencers} sampleInfluencerDetailedData={sampleInfluencerDetailedData} samplePostDetailedData={samplePostDetailedData} sampleMostEngagingPosts={sampleMostEngagingPosts} /></>}
           {((addedLinksDetails && addedLinksDetails.length>0) && postFilterData.length === 0) &&
        <>
          <strong>Post Links:</strong>
          <div className="post-links row">{addedLinksDetails && addedLinksDetails?.map((postLink:any)=>{
            return <div className="col-md-3">{loading ? <Skeleton /> : 
            <YouTubeEmbed videoId={postLink?.post_url} />}
            </div>
          })}</div>
        </>
        }
        {
          (() => {
            if (postFilterData.length > 0) {
              return (
                <>
                  <div className="toggle-button-div sec-stockchart-w-tab">
                    <TabContext value={value}>

                      <TabList className='nav sub-tabs'
                        onChange={handleChange}
                      >
                        <Tab
                          className='nav-link'
                          label={`Total Views`}
                          value="1"
                          icon={<span className="material-symbols-rounded">visibility</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                        <Tab className='nav-link'
                          label={`Total Likes`}
                          value="2"
                          icon={<span className="material-symbols-rounded">favorite</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                        <Tab className='nav-link'
                          label={`Total Comments`}
                          value="3"
                          icon={<span className="material-symbols-rounded">chat_bubble</span>}
                          style={{
                            textTransform: "none",
                            fontSize: "16px",
                            flexGrow: 1,
                          }}
                        />
                        
                      </TabList>
                      <TabPanel className="px-0" value="1">
                        <HighchartsReact highcharts={Highcharts} options={playsOptions} />

                      </TabPanel>
                      <TabPanel className="px-0" value="2">
                        <HighchartsReact highcharts={Highcharts} options={likesOptions} />
                      </TabPanel>
                      <TabPanel className="px-0" value="3">
                        <HighchartsReact highcharts={Highcharts} options={commentOptions} />
                      </TabPanel>
                      
                    </TabContext>

                  </div>

                  <>
                    <div className='d-flex flex-column'>
                      <strong className='me-auto mb-1'>Most Engaging Posts</strong>
                      <>
                        {(() => {
                          const sortedData = [...postFilterData].sort((a: any, b: any) => (b.selectedValue > a.selectedValue) ? 1 : (b.selectedValue < a.selectedValue) ? -1 : 0).slice(0, 5);
                          return (<MostEngagingPosts sortedData={sortedData} selectCampaign={selectedCampaign} />
                          );
                        })()}
                      </>
                      {/* {/* )} */}
                    </div>
                    {(
                      <div className="analytics-most-engaging">
                        <div className='d-flex flex-column'>
                          <strong className='mb-1'>Most Engaging Influencers</strong>
                          {(() => {
                            const sortedData = influencerFilterData.sort((a: any, b: any) => (b.total_er > a.total_er) ? 1 : (b.total_er < a.total_er) ? -1 : 0).slice(0, 5);
                            return <MostEngagingInfluencer sortedData={sortedData} selectCampaign={selectedCampaign} />
                          })()}

                        </div>

                      </div>
                    )}
                  </>

                  <div className="toggle-button-div2">
                    <div className="d-flex flex-wrap">
                      <strong>Detailed Insights</strong>
                    </div>
                     <TabContext value={value2}>

                      <TabPanel value="1" className='px-0'>
                        <div className="toggle-button-div3">
                          <TabContext value={value4}>
                            <>
                              <TabList
                                onChange={handleChange4}
                                className="filter-tab-list nav sub-tabs mb-3"
                              >
                                <Tab className='nav-link'
                                  label={`Posts`}
                                  value="1"
                                  style={{ textTransform: "none", fontSize: "16px" }}
                                />
                                <Tab className='nav-link'
                                  label={`Influencers`}
                                  value="2"
                                  style={{ textTransform: "none", fontSize: "16px" }}
                                />
                              </TabList>
                            </>
                            <TabPanel value="1" className='px-0 pt-0'>
                              <div className="post-table">
                                <>
                                  <DetailedInsightsPost columnDisplayName={columnDisplayNamePostDetailedData}
                                    orderByColumnName=""
                                    inputDataSource={postFilterData}
                                    sortingColumnType={PostDetailedData}
                                    renderData={postFilterData}
                                    setRenderData={(e: any) => setPostFilterData(e)}
                                    selectCampaign={selectedCampaign}
                                    hasShareAnalytics={hasShareAnalytics}/>
                                </>

                              </div>
                            </TabPanel>
                            <TabPanel value="2" className='px-0 pt-0'>
                              <div className="influencer-table">

                                <DetailedInsightsInfluencer columnDisplayName={columnDisplayNameInfluencerDetailedData}
                                  orderByColumnName=""
                                  inputDataSource={influencerFilterData}
                                  sortingColumnType={InfluencerDetailedData}
                                  renderData={influencerFilterData}
                                  setRenderData={(e: any) => setInfluencerFilterData(e)}
                                  selectCampaign={selectedCampaign} />
                              </div>
                            </TabPanel>
                          </TabContext>
                        </div>
                      </TabPanel>
                    </TabContext> 

                  </div >
                </>
              )
            }

          })()

        }
        </div>
       
      </div >)}</>;
};