import React, { useState, useEffect } from 'react';
import { TableComponent, SortingColumnsType, } from "../../../components/table-component/table"
import { AnalyticsService, ApplyCampaignService, LocalService } from '../../../core/services';
import { FormValidation } from "../../../core/utility";
import { TextField, Select, OutlinedInput, MenuItem, InputLabel, FormControl } from '@mui/material';
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { BASE_URL_WEB } from "../../../config/config";
import CommonPopUp from '../../../components/popup/common-popup';
import { MenuPropsSelect } from '../../../core/utility/style';
import { NavLink } from 'react-router-dom';
const localService = new LocalService();
const analyticsService = new AnalyticsService();

const applyCampaignService = new ApplyCampaignService();
const columnDisplayName = {
  influencerName: "Influencer Name",
  postLink: "Post Link",
};
interface ErrorTypes {
  status: boolean;
  message: string;
}
const sortingColumnType: SortingColumnsType[] = [
  {
    name: "influencerName",
    columnSize: ".875rem",
  },
  {
    name: "postLink",
    columnSize: "1rem",
  },
  {
    name: "",
    columnSize: ".3125rem",
  },
];


interface FormData {
  postLink: string;
  influencer: string;

}

interface AddAnalyticsBrandProps {
  open: boolean;
  campaign_id: number;
  onClose: () => void;
  post_platform: string,
  sm_id: number;
  totalPost?:any;
  getSubscriptionDetail?:any;
  handleOpenUpgradeBrandModal?:((value:boolean) => void) | undefined;
  campaignType:string;
  onUpdatePostLinks?:any
}

const AddAnalyticsBrand: React.FC<AddAnalyticsBrandProps> = ({ open, onClose, campaign_id, post_platform, sm_id, getSubscriptionDetail,  totalPost, handleOpenUpgradeBrandModal, campaignType, onUpdatePostLinks}) => {
  const formValidation = new FormValidation();
  const [postLink, setPostLink] = useState('');
  const [influencer, setInfluencer] = useState('');
  const [formDataList, setFormDataList] = useState<FormData[]>([]);
  const [errorPostLink, setErrorPostLink] = useState<ErrorTypes>();
  const [influencerList, setInfluencerList] = useState<string[]>([]);
  const [previousLinks, setPreviousLinks] = useState<string[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [popupMessage, setPopupMessage] = useState<string>('');

  useEffect(() => {
    getApprovedInfluencerList();
  }, []);

  const handleSubmit = async () => {
    let is_duplicate_link = false
    let is_valid_link =false
    const campaignId = campaign_id
    const res = await analyticsService.getAnalyticsLinks(campaignId)

    if(post_platform == "Instagram"){
      setErrorPostLink(formValidation.CheckInstagramUrl(postLink));
      is_valid_link = formValidation.CheckInstagramUrl(postLink).status
    }
    else
    {
      setErrorPostLink(formValidation.CheckYouTubeUrl(postLink));
      is_valid_link = formValidation.CheckYouTubeUrl(postLink).status
    }

     // Check if postLink already exists in the response data
    is_duplicate_link = (res.data.rows).some((item:any) => item.post_url === postLink);
    
    if (is_duplicate_link) {
      setErrorPostLink({ status: false, message: 'This link has already been added.' });
      return; 
    }
    if(is_valid_link){
      if(getSubscriptionDetail.isBasicUser && campaignType === 'Paid'){
        if((formDataList.length + totalPost) < (getSubscriptionDetail.add_post_link_limit)){
          const formData: FormData = { influencer, postLink };
          setFormDataList([...formDataList, formData]);
          setPostLink('');
          setInfluencer('');
          }else{
            if(handleOpenUpgradeBrandModal){
              // handleOpenUpgradeBrandModal(true)
              setOpenModal(true)
              setPopupMessage('You can add only 1 link for this FOC campaign. To add more, please upgrade.')
            }
          }
      }else if (getSubscriptionDetail.isBasicUser && campaignType === 'Barter'){
        if((formDataList.length + totalPost) < (getSubscriptionDetail.barter_add_post_link_limit)){
          const formData: FormData = { influencer, postLink };
          setFormDataList([...formDataList, formData]);
          setPostLink('');
          setInfluencer('');
          }else{
            if(handleOpenUpgradeBrandModal){
              setOpenModal(true)
              setPopupMessage('A maximum of 10 links can be added for this Barter campaign. To add more, please upgrade or')
            }
          }
      }
      // else if (getSubscriptionDetail.isBasicUser == false && getSubscriptionDetail.amount == 2500){
      //   if((formDataList.length + totalPost) < 40){
      //     const formData: FormData = { influencer, postLink };
      //     setFormDataList([...formDataList, formData]);
      //     setPostLink('');
      //     setInfluencer('');
      //     }else{
      //       if(handleOpenUpgradeBrandModal){
      //         setOpenModal(true)
      //         setPopupMessage("You can add up to 30 links. You've reached the maximum limit. To add more links, please contact us.")
      //       }
      //     }
      // }
      else{
          const formData: FormData = { influencer, postLink };
          setFormDataList([...formDataList, formData]);
          setPostLink('');
          setInfluencer('');
      }
      }
     
  };

  const removeItem = (index: number) => {
    const updatedFormDataList = formDataList.filter((_, i) => i !== index);
    setFormDataList(updatedFormDataList);
    setPreviousLinks([]);
    localService.toastify("Post link deleted successfully!");
  };


  const handleSubmitUserForm = async () => {
    const res = await applyCampaignService.uploadPostLinkAnalytics({
      data: {
        campaign_id: campaign_id,
        post_platform: post_platform,
        formDataList: formDataList,
        sm_id: sm_id
      },
    });
    if (res) {
      if (res.data.status.status == true) {
        localService.toastify(res?.data?.status?.message);
        onClose()
         // Call the parent's function to update post links
          await onUpdatePostLinks();
      }
      else {
        setOpenModal(true)
        setPopupMessage(res?.data?.status?.message)
        // localService.toastify(res?.data?.status?.message);
      }
    }
  };

  const handlePostLinkChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    if (previousLinks.includes(value)) {
      setErrorPostLink({ status: true, message: "Post Link is a duplicate" });
    } else {
      setPostLink(value);
      setPreviousLinks([...previousLinks, value]);
    }
  }

  const getApprovedInfluencerList = async () => {
    const res = await ApplyCampaignService.influencerList({
      campaign_id: campaign_id,
      proposalStatus: 'Accepted',
    })

    if (res) {
      setInfluencerList(res.data.rows)
    }

  }

  return (
    <>
      <CommonPopUp
        modalTitle={`Add ${post_platform} post`}
        openModal={open}
        onClose={onClose}
        className="modal-medium"
      >
        <div className='add-post-link-sec d-flex align-items-center row mt-3 pb-3'>
          <div className="col-12 col-md">
          <FormControl fullWidth size="small">
            <InputLabel id="demo-multiple-name-label">Select an influencer</InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              className='w-100 input-select add-post-select-infl'
              value={influencer}
              onChange={(event) => {
                setInfluencer(event.target.value); // Update influencer state with the selected value
              }}
              input={<OutlinedInput label="Select an influencer" />}
              MenuProps={MenuPropsSelect}
              >
              {influencerList ? (
                influencerList.map((res: any) => (
                  <MenuItem
                    key={post_platform === "Instagram" ? res.ig_id : res.yt_id}
                    value={post_platform === "Instagram" ? res.ig_id : res.yt_id}
                  >
                    {post_platform === "Instagram" ? res.username : res.title}
                  </MenuItem>
                ))
              ) : <MenuItem
              key={1}
              value={1}
            >
              {"No Data"}
            </MenuItem>}
              <MenuItem value="Other Influencer">Other Influencer</MenuItem>
            </Select>
          </FormControl>
          </div>
          <div className="col-12 col-md">
            <TextField
              label="Post Link"
              value={postLink}
              className="w-100 mt-0 add-post-link-url"
              onChange={handlePostLinkChange}

              size="small"
              fullWidth
              margin="normal"
            />
            <span className="error">{errorPostLink?.message}</span>
          </div>
          <div className="col-12 col-md">
            <button
              onClick={handleSubmit}
              className="btn btn-outline-primary">
               Add Post
            </button>
          </div>
        </div>
        {
          (() => {
            if (formDataList.length > 0) {
              return (
                <>
                  <TableComponent
                    columnDisplayName={columnDisplayName}
                    orderByColumnName=""
                    inputDataSource={formDataList}
                    sortingColumnType={sortingColumnType}
                    renderData={formDataList}
                    setRenderData={(e) => setFormDataList(e)}
                  >
                    {formDataList.map((data: any, index: number) => {
                      return (
                        <TableRow key={index} hover role="checkbox">
                          <TableCell>
                            {
                              (()=>{
                                if(data?.influencer == "Other Influencer"){
                                  return data?.influencer

                                }
                                else{
                                  influencerList
                                  .filter((item: any) => {
                                    if (post_platform === "Instagram") {
                                      return item.ig_id === data?.influencer;
                                    } else {
                                      return item.yt_id === data?.influencer;
                                    }
                                  })
                                  .map((filteredItem: any) => {
                                    return post_platform === "Instagram" ? (filteredItem.username !== null ? filteredItem.username : "Other Influencer"): (filteredItem.title !== null ? filteredItem.title : "Other Influencer");
                                  })}
    
                                
   
                              })()
                            }
                          </TableCell>
                          <TableCell>{data?.postLink}</TableCell>
                          <TableCell>
                            <span style={{ cursor: 'pointer' }}
                              onClick={(e) => removeItem(index)} className='material-symbols-rounded'>
                              delete
                            </span>
                          </TableCell>
                        </TableRow>
                      );
                    })}

                  </TableComponent>
                  <button className='my-3 btn btn-primary' onClick={handleSubmitUserForm}>Submit</button>
                </>
              )
            }
          })()
        }
      </CommonPopUp>
      {openModal && (
        <CommonPopUp
          modalTitle={popupMessage.includes('30') ? "" : "Upgrade to Premium:"}
          modalContent=""
          openModal={openModal}
          onClose={onClose}
          className="modal-medium mx-auto"
        >
          <div className="">
            {/* <div>
              <p>{popupMessage}</p>
            </div> */}

            <div>
              <p>
                {popupMessage}
                {popupMessage.includes('Barter') && (
                  <a className="ms-2" href={`${BASE_URL_WEB}/contact_us`}>
                    contact us
                  </a>
                )}
              </p>
            </div>

            <div className='popup-footer' style={{ marginTop: "32px" }}>
              <div className='row'>
                <div className='col text-end'>
                  {popupMessage.includes('30') ? (
                    <a className="btn btn-primary ms-2 btn-mui" href={`${BASE_URL_WEB}/contact_us`}>
                      Contact us
                    </a>
                  ) : (
                    <a className="btn btn-primary ms-2 btn-mui" href="/brand/settings/subscription">
                      Upgrade Now
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </CommonPopUp>
      )}
    </>
  );
};

export default AddAnalyticsBrand;