import { HttpService } from "./https.service";
import { BASE_URL } from "../../config/config";
import {
  GetUploadedURL,
  DeleteUploadURL,
  AddUploadURL,
  UpdateUploadURL,
  ResponseType,
  AnalyticsBrand
} from "custom-type";

const http = new HttpService();

export class AnalyticsService {
  async getUploadedUrl(data: GetUploadedURL): Promise<ResponseType> {
    const path = BASE_URL + "/get/uploaded/url";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async deleteUploadURL(data: DeleteUploadURL): Promise<ResponseType> {
    const path = BASE_URL + "/delete/uploaded/url";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async addUploadURL(data: AddUploadURL): Promise<ResponseType> {
    const path = BASE_URL + "/add/uploaded/url";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async updateUploadURL(data: UpdateUploadURL): Promise<ResponseType> {
    const path = BASE_URL + "/update/uploaded/url";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async brandAnalytics(data:any): Promise<AnalyticsBrand> {
    const path = BASE_URL + "/get/brand/analytics";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }
  async shareBrandAnalytics(data:any): Promise<AnalyticsBrand> {
    const path = BASE_URL + "/get/share-analytics";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }
    async shareBrandAnalyticsYoutube(data:any): Promise<AnalyticsBrand> {
    const path = BASE_URL + "/get/share-analytics-yt";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async brandAnalyticsYoutube(data:any): Promise<AnalyticsBrand> {
    const path = BASE_URL + "/get/brand/analytics-yt";
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`Create Plan Service :: ${error}`);
    }
  }

  async getAnalyticsLinks(campaignId: any) {
    const path = BASE_URL + `/analytics/postlinks/details?campaign_id=${campaignId}`;
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`Get getAnalyticsLinks Error :: ${error}`);
    }
  }

  async analyticsRefreshInight(data: any) {
    const path = BASE_URL + '/analytics/update/insights';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`analyticsRefreshInight :: ${error}`);
    }
  }
  async analyticsShareLinks(data: any) {
    const path = BASE_URL + '/analytics/share/details';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`analyticsShareLinks :: ${error}`);
    }
  }
  async analyticsSharedList(data: any) {
    const path = BASE_URL + `/analytics/share/list?campaign_id=${data.campaign_id}&plan_id=${data.planID}`;
    try {
      const response = await http.getRequest(path, null);
      
      return response;
    } catch (error) {
      throw new Error(`analyticsSharedList :: ${error}`);
    }
  }
  async checkEmailExists(payload: any) {
    const path = BASE_URL + `/analytics/check-email?campaign_id=${payload.campaign_id}&email=${payload.email}`
    try {
      const response = await http.getRequest(path, null);
      return response;
    } catch (error) {
      throw new Error(`analyticsSharedList :: ${error}`);
    }
  }

  async deleteSharedUser(payload:any) {
    const path = BASE_URL + `/analytics/share/delete`;
    try {
      const response = await http.postRequest(path, payload, null);
      return response;
    } catch (error) {
      throw new Error(`analyticsSharedDeleteUser :: ${error}`);
    }
  }

  async analyticsDeleteUrl(data: any) {
    const path = BASE_URL + '/analytics/delete/url';
    try {
      const response = await http.postRequest(path, data, null);
      return response;
    } catch (error) {
      throw new Error(`analyticsDeleteUrl :: ${error}`);
    }
  }
  async verifySharedUser(payload: any) {
    const path = `${BASE_URL}/analytics/share/verify-token?campaignToken=${payload.campaignToken}&platform=${payload.campaignPlatform}`;
    try {
        const response = await http.getRequest(path, null);
        return response;
    } catch (error) {
        console.error("Error in verifySharedUser:", JSON.stringify(error, Object.getOwnPropertyNames(error)));
        throw new Error(`verifySharedUser :: ${error}`);
    }
  }

  async analyticsSharePublicLink(payload: any) {
    const path = `${BASE_URL}/get/share-link?campaignID=${payload.campaign_id}&platform=${payload.campaignPlatform}`;
    try {
        const response = await http.postRequest(path, payload, null);
        return response;
    } catch (error) {
        console.error("Error in verifySharedUser:", JSON.stringify(error, Object.getOwnPropertyNames(error)));
        throw new Error(`verifySharedUser :: ${error}`);
    }
  }
}
