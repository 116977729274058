import { IconButton, TextField } from '@mui/material'
import React from 'react'
import MaterialSymbol from '../../../../components/icons/material-symbol'

type Props = {
    userShareList?:any;
    handleEmailChange:any;
    errorEmail?:any;
    inviteEnabled?:boolean;
    handleOpenDeleteInfluencer: (id: number) => void
    handleSubmitInvite: (e: any) => Promise<void>
    handleKeyPress: (e: any) => void
    inviteInput: string
    handleSendSharedList:any
    handleOpenSharedDeleteUser:any;
    sharedUserList:any;
    loading?:boolean


    
}

const FormShareAnalytics = (props: Props) => {
  return (
    <>
      <div className="share-input d-flex">
          <div className="d-flex flex-column me-2 flex-1 w-100 mb-3">
              <TextField onKeyPress={props.handleKeyPress} onChange={props.handleEmailChange} defaultValue="Share by email" value={props.inviteInput}
              size="small" placeholder="Share by email" className="form-control  me-2 flex-1" />
            <span className="error ">{props.errorEmail && props.errorEmail?.message}</span>
          </div>
            <button className={`${props.inviteEnabled ? "" : "btn-disabled"} btn btn-outline-primary  btn-mui align-self-start`} onClick={props.handleSubmitInvite}>Invite</button>
      </div>
      <div className="share-invited-list">
            <ul>
              {(props?.userShareList && props?.userShareList.length > 0)  && props.userShareList.map((user:{ email: string, id: number})=> {
              return <li>
              <div className="share-invited-actions">
                <span className="me-auto">{user?.email}</span>
                <IconButton style={{cursor:'pointer'}}>
                    <MaterialSymbol onClick={()=>props.handleOpenDeleteInfluencer(user?.id)} iconName="delete" fontweight="200" />
                </IconButton>
              
              </div>
          </li>
        })}</ul>
      </div>
          <>{(props?.sharedUserList && props?.sharedUserList.length > 0) &&
            <><span className="fw-600">{`People with access: ( ${props?.sharedUserList.length} )`}</span>
            <div className="shared-user-list">
              <ul className="list-style-none">
                {props?.sharedUserList.map((user: { id: number, email: string }) => {
                  return <li className="d-flex" key={user.id}><span className="me-auto">{user.email}</span>
                   <IconButton style={{ cursor: 'pointer' }}>
                      <MaterialSymbol onClick={() => props.handleOpenSharedDeleteUser(user.id)} iconName="delete" fontweight="200" className="shared-user-delete" />
                  </IconButton></li>
                })}</ul>
            </div></>}
            {props.userShareList.length > 0 && (
                <div className="popup-footer" style={{ position: 'sticky', bottom: 1, zIndex: 1, display:'flex', justifyContent:'flex-end' }}>
                  <button className="btn btn-primary" onClick={props.handleSendSharedList}>{props?.loading ? "Sending..." : "Send"}</button>
            </div>)
            }</>
    </>
  )
}

export default FormShareAnalytics