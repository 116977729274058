import React, { useState, Fragment, useEffect, Suspense, FormEvent, useRef, RefObject } from "react";
import Tabs from '@mui/material/Tabs';
import { Button } from "@mui/material";
import { connect } from "react-redux";
import { Loading } from "../../../redux/action";
import {
  LocalService,
  DiscoverService} from "../../../core/services";
import { Methods } from "../../../core/utility";
import { InfluencerTypes, Platforms } from '../../../core/enums';
import { DefaultUserProfile } from "../../../config/config";
import { Link, useLocation } from "react-router-dom";
// import { TableComponent } from './discover-table';
import { Col, Container, Row } from "react-bootstrap";
import HeadingStyleSecondary from "../../../components/heading-style/heading-style-secondary";
import { Delete, Launch, Search, YouTube } from "@mui/icons-material";
import CuratedList from "../../../components/curated-list/curated-list";
import { InfluencerFilters } from './influencers-filters';
import { AudienceFilters } from './audience-filters';
import { CustomCollapse } from './custom-designs';
import AddToList from "../../../pages/brand/discover/add-to-list";
import SaveFromList from "./save-from-list";
import IconYouTube from '../../../components/icons/icon-youtube';
import { CommonTableComponent } from "../../../components/common-table-component/common-table-component";
// import common popup upgrade brand popup component for FOC users
import CommonPopUp from "../../../components/popup/common-popup";
import UpgradeBrandPopUp from "../../../components/popup/upgrade-brand-popup";
import InstagramIcon1 from "../../../components/icons/instagram-icon1";
import ProfileViewRestriction from "../../../components/popup/profile-view-restriction";
import Close from "@mui/icons-material/Close";
import useBodyClass from "../../../hooks/useBodyClass";

const localService: LocalService = new LocalService();
const discoverService: DiscoverService = new DiscoverService();
const methods: Methods = new Methods();

interface Props {
  UserReducer: any;
  loading: (data: boolean) => void;
  getSubscriptionDetail: any;
}

interface CustomRange {
  min_range: number;
  max_range: number;
}

type FiltersType = {
  user_id: number,
  account_id: number,
  brand_id: number,
  influencer_id: number,
  user_role_id: number,
  page: number, // page no
  view_records: number, // it's no or records in one page
  readOnly: number[], // it will use when tab change
  searchKeyword: string[], // ["anant", "kapil"]
  influencer_type_id: number[], // 1, 2, 3, 4, 5, 6, 7
  custom_range: CustomRange,
  platfrom: string, // youtube, instagram
  category_id: number[], // 1, 2, 3, 4 etc...
  channel_view: { min_range: number, max_range: number }[],
  influencer_metrics: {
    location_id: number[], // 1, 2, 3, 4, 5 etc...
    gender: string[], // M, F
    age_range: { min_range: number, max_range: number }[] // [{"min_range": 0, "max_range": 20}]
  },
  min_enagement_rate: { min_range: number, max_range: number }[], // {"min_range": 0, "max_range": 20}
  audience_metrics: {
    location_name: string[],// ["Roorkee, Uttarakhand"],
    gender: string[], // ["M", "F"]
    age_range: string[] //["13-17"]
  },
  insert_history: boolean
}
/* NOTE IF PAGE NOT REQUIRED ANY VALIDATION THEN SKIP THE payload value */
const intialTableDataFilters: FiltersType = {
  user_id: 0,
  account_id: 0,
  brand_id: 0,
  influencer_id: 0,
  user_role_id: 0,
  page: 1, // page no
  view_records: 10, // it's no or records in one page
  readOnly: [1], // it will use when tab change
  searchKeyword: [], // ["anant", "kapil"]
  influencer_type_id: [], // 1, 2, 3, 4, 5, 6, 7
  custom_range: { "min_range": 0, "max_range": 0 },
  platfrom: "instagram", // youtube, instagram
  category_id: [], // 1, 2, 3, 4 etc...
  channel_view: [],
  influencer_metrics: {
    location_id: [], // 1, 2, 3, 4, 5 etc...
    gender: [], // M, F
    age_range: [] // [{"min_range": 0, "max_range": 20}]
  },
  min_enagement_rate: [], // {"min_range": 0, "max_range": 20}
  audience_metrics: {
    location_name: [],// ["Roorkee, Uttarakhand"],
    gender: [], // ["M", "F"]
    age_range: [] //["13-17"]
  },
  insert_history: false
};

const intialInfluencerCounts = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 0,
  6: 0,
  7: 0
};

const Discover: React.FC<Props> = ({ loading, ...props }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [activeTabBoolean, setActiveTabBoolean] = useState(true);
  const locationHook = useLocation();
  // CLEAR ALL DATA
  const [clearFilter, setClearFilter] = useState(false);
  /* CURATED LISTS */
  const [curatedLists, setCuratedLists] = useState<any[]>([]);
  /* SEARCH BAR */
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchList, setSearchList] = useState<any[]>([]);
  const [searchToggle, setSearchToggle] = useState(false);
  /* TABLE DATA */
  const [totalInfluencerCounts, setTotalInfluencersCount] = useState<number>(0);
  const [infCounts, setInfCounts] = useState<any>(intialInfluencerCounts);
  const [tableDatas, setTableDatas] = useState<any[]>([]);
  /* TABLE SELECT INFLUENCERS LIST */
  const [selectedInfluencer, setSelectedInfluencer] = useState<any>([]);
  // LIST STATES
  const [showPop, setShowPop] = useState(false);
  // const [influencerAddPrivateListID, setinfluencerAddPrivateListID] = useState<{ list_id: number, list_type: string } | null>(null);
  const [addInfluencerDefaultSelectList, setAddInfluencerDefaultSelectList] = useState<any>(null);

  const [disablePlatfrom, setDisablePlatfrom] = useState<('instagram' | 'youtube' | Omit<'instagram' | 'youtube', string>)>();
  const [filterData, setFilterData] = useState({
    ...intialTableDataFilters,
    user_id: props.UserReducer.user_id,
    account_id: props.UserReducer.account_id,
    brand_id: props.UserReducer.brand_id,
    influencer_id: props.UserReducer.influencer_id,
    user_role_id: props.UserReducer.user_role_id,
    platfrom: locationHook.state ? (locationHook.state.list_type + '').toLocaleLowerCase() : 'instagram'
  });

  // FOC useStates
  // set profile view limit Modal variables and usestate
  const [profileViewLimitPopup, setProfileViewLimitPopup] = useState(false);
  const [profileViewCount, setProfileViewCount] = useState(0);
  const [profileViewLimit, setProfileViewLimit] = useState(0);
  const [profileViewModalTitle, setProfileViewModalTitle] = useState('View Profile');

  // set profile view limit Modal variables and usestate
  const [profileCompareLimitPopup, setProfileCompareLimitPopup] = useState(false);
  const [profileCompareCount, setProfileCompareCount] = useState(0);
  const [profileCompareLimit, setProfileCompareLimit] = useState(0);
  const [profileCompareModalTitle, setProfileCompareModalTitle] = useState('Compare Profiles');

  const [compareUrl, setCompareUrl] = useState('');

  const [selectedSmId, setSelectedSmId] = useState(0);
  const [selectedInfluencerId, setSelectedInfluencerId] = useState(0);

  // set search limit Modal variables and usestate
  const [searchLimitPopup, setSearchLimitPopup] = useState(false);
  const [searchCount, setSearchCount] = useState(0);
  const [searchLimit, setSearchLimit] = useState(0);
  const [searchLimitModalTitle, setSearchLimitModalTitle] = useState('Search');
  const [focModalSearchMethod, setFocModalSearchMethod] = useState('');
  const [filterCount, setFilterCount] = useState(null);
  const [clearSearch,setClearSearch]= useState(true)
  // set upgrade brand Modal variables and usestate
  const [openUpgradeBrandModal, setopenUpgradeBrandModal] = useState(false);
  const [isModified, setIsModified] = useState(false);

  // to check insert is required or not
  const [insert_history, setInsertHistory] = useState(false);
  // End of FOC useStates

  useEffect(() => {
    fillTableData();
    getCuratedList();
  }, [activeTab, filterData.platfrom, filterData.readOnly, filterData.page, filterData.view_records]);

  // UPDATEING SEARCH KEYWORD IN FILTER
  useEffect(() => {
    if (searchKeyword.length > 3) {
      searchBar(searchKeyword);
    }
    if(searchKeyword.length > 0){
      setClearSearch(false)
    }
    if (searchKeyword.length === 0) {
      setClearSearch(true);
    }
  }, [searchKeyword]);

  useEffect(() => {
    setSelectedInfluencer([]);
  }, [filterData.platfrom]);

  useEffect(() => {
    if (locationHook.state) {
      setDisablePlatfrom((locationHook.state.list_type).toString().toLowerCase());
      setAddInfluencerDefaultSelectList(locationHook.state);
    }
  }, [locationHook]);

  useEffect(() => {
    if (clearFilter) {
      setClearFilter(!clearFilter);
      // fillTableData();
    }
  }, [clearFilter]);
  // useEffect(() => {
  //   if (clearSearch) {

  //     setClearSearch(!clearSearch)
  //     fillTableData();
  //   }
  // }, [clearSearch]);
  useEffect(() => {
    if (activeTabBoolean) {
      callFillTableData();
    }
  }, [activeTabBoolean]);

  useEffect(() => {
    callFillTableData();
  }, [filterData.searchKeyword]);

  useEffect(() => {
    if(filterData.influencer_type_id?.length > 0 || filterData.category_id?.length > 0 || filterData.influencer_metrics?.location_id?.length > 0 || filterData.influencer_metrics?.gender?.length > 0 || filterData.influencer_metrics?.age_range?.length > 0 || filterData.custom_range?.min_range > 0 || filterData.custom_range?.max_range > 0 || filterData.channel_view?.length > 0 ||  filterData.min_enagement_rate?.length > 0 || filterData.audience_metrics?.location_name?.length > 0 || filterData.audience_metrics?.gender?.length > 0 || filterData.audience_metrics?.age_range?.length > 0){
      // setClearSearch(false);
      setIsModified(true)
    }
    else {
      setIsModified(false)
    }
    filterDataCount();
  },[filterData]);

  

  function callFillTableData() {
    if (activeTabBoolean) {
      fillTableData();
    } else {
      setActiveTabBoolean(true);
    }
  }

  /**
  * get the curated list
  */
  const getCuratedList = async () => {
    try {
      const res = await discoverService.getCuratedList(filterData.platfrom);
      if (res.data.status === 1) {
        setCuratedLists(res.data.rows);
      }
    } catch (error) {
      console.log(`getCuratedList error :: ${JSON.stringify(error)}`);
    } finally {
    }
  }

  /**
   * Fills the table data by making an API call to retrieve the filtered data.
   */

  function fillTableData() {
    setIsLoading(true);
    let insertHistory = insert_history;
    if(clearFilter){
      setFilterData({
        ...intialTableDataFilters,
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        brand_id: props.UserReducer.brand_id,
        influencer_id: props.UserReducer.influencer_id,
        user_role_id: props.UserReducer.user_role_id,
        searchKeyword: [searchKeyword],
        platfrom: filterData.platfrom
      });
      // setClearSearch(false)
    }
    discoverService.filters({ ...filterData, readOnly: [activeTab], insert_history: insertHistory }).then((res: {
      data: { rows: [], over_all_count: { influencer_type_id: number, influencer_type: string, count_records: number }[] }
    }) => {
      setInsertHistory(false);
      let total = 0;
      if (activeTabBoolean && res.data.over_all_count.length > 0) {
        setActiveTab(res.data.over_all_count[res.data.over_all_count.length - 1].influencer_type_id);
      }
      setInfCounts(intialInfluencerCounts);
      setTableDatas(res.data.rows);
      for (let data of res.data.over_all_count) {
        setInfCounts((prev: any) => ({ ...prev, [data.influencer_type_id]: data.count_records }));
        total += data.count_records;
      };
      setInfluencerTabFocus()
      setTotalInfluencersCount(total);
    }).finally(() => setIsLoading(false));
  };

  const filterDataCount = () => {
    let insertHistory = insert_history;
    discoverService.filters({ ...filterData, readOnly: [activeTab], insert_history: insertHistory, count:true }).then((res:any) => {
      setInsertHistory(false);
      setFilterCount(res.data.over_all_count);
      
    })
  }

  function setInfluencerTabFocus() {
    try {
      if (infCounts[activeTab] === 0) {
        Object.entries(infCounts).forEach(([key, value]: any) => {
          if (value > 0) {
            setActiveTab(key);
            throw new Error(`Active Key Set Success :: activeTab ${key}`)
          }
        })
      }
    } catch (error) {
      console.log(error)
    }
  }

  /**
   * Handles the checkbox change event.
   * @param checked - The new checked state of the checkbox.
   * @param data - The data associated with the checkbox.
   */
  const handleCheckbox = (checked: boolean, data: any) => {
    let tempStoreData: any[] = [...selectedInfluencer];
    // if((selectedInfluencer.length + addInfluencerDefaultSelectList?.influencer_counts) === 25){
    //   if(checked){
    //     handleOpenUpgradeBrandModal();
    //   }else{
    //     const index = tempStoreData.findIndex(
    //       (value) => JSON.stringify(value) === JSON.stringify(data)
    //     );

    //     if (index !== -1) {
    //       tempStoreData.splice(index, 1);
    //     }
    //   }

    // }else{
    if (checked) {
      tempStoreData.push(data);
    } else {
      const index = tempStoreData.findIndex(
        (value) => JSON.stringify(value) === JSON.stringify(data)
      );

      if (index !== -1) {
        tempStoreData.splice(index, 1);
      }
    }
    setSelectedInfluencer(tempStoreData);
    // }

  };

  /**
   * Handles navigation to profile insight page based on platform.
   * @param id - The ID of the profile.
   * @param influencerId - The ID of the influencer.
   */
  // const handleNavigate = (id: number | string, influencerId?: string | number) => {
  //   if (filterData.platfrom === "instagram") {
  //     window.open(`/brand/profile-insight?sm_id=${id}&influencerId=${influencerId}`, "_blank");
  //   } else if (filterData.platfrom === "youtube") {
  //     window.open(`/brand/profile-insight-yt?sm_id=${id}&influencerId=${influencerId}`, "_blank");
  //   }
  // };

  const handleNavigate = (id: number | string, influencerId: string | number) => {
    if (filterData.platfrom === "instagram") {
      window.open(`/brand/profile-insight?sm_id=${id}&influencerId=${influencerId}`, "_blank");
    }
    if (filterData.platfrom === "youtube") {
      window.open(`/brand/profile-insight-yt?sm_id=${id}&influencerId=${influencerId}`, "_blank");
    }
    if (filterData.platfrom === "curatedInstagram") {
      window.open(`/brand/profile-insight?sm_id=${id}&influencerId=${influencerId}`, "_blank");
    }
  };

  /**
  * Handles the search field change event.
  */
  // const handleSearchField = async ({ target, }: React.ChangeEvent<HTMLInputElement>) => {
  //   const inputValue = target.value.toLowerCase();
  //   setSearchKeyword(inputValue);
  //   const icon:any = document.getElementById("close-icon-discover");
  //     if (icon && searchKeyword.length >0 ) {
  //       icon.classList.remove('d-transparent');
  //     }
  // }


  const handleSearchField = async ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = target.value.toLowerCase();
    setSearchKeyword(inputValue); // Update state with the new input value

    const icon: any = document.getElementById("close-icon-discover");

    // Check inputValue.length instead of searchKeyword.length
    if (icon && inputValue.length > 0) {
      icon.classList.remove('d-transparent');  // Show icon
    } else if (icon) {
      icon.classList.add('d-transparent');  // Hide icon if input is empty
    }
  };


  /**
  * show the recomended search list
  */
  const searchBar = async (inputValue: string) => {
    try {
      const response = await discoverService.getSearchInfluencer({
        platform_type: filterData.platfrom,
        value: inputValue,
        insert_history: false,
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        brand_id: props.UserReducer.brand_id,
        influencer_id: props.UserReducer.influencer_id
      });
      if (response.data.status.status === 1) {
        setSearchList(response.data.rows.total_result);
      }
    } catch (error) {
      console.error(`getInfluencerCount error :: ${JSON.stringify(error)}`);
    }
  };


  const renderProfileListItem = (item: any) => {
    const sm_id = filterData.platfrom === 'instagram' ? item?.ig_id : item?.yt_id
    return (
      <li className="insta-search-list" key={sm_id}>
        <div className="col">
          <div className="d-flex flex-wrap align-items-center">
            <Link to="javascript:void(0);" onClick={() => handleProfileView(sm_id, item?.influencer_id)}>
              {filterData.platfrom === 'instagram' ? <InstagramIcon1 width={25} /> : <YouTube fontSize="small" style={{ color: 'red' }} />}
            </Link>
            <strong>
              <Link to="javascript:void(0);" className="insta-username insta-search-username mx-2" onClick={() => handleProfileView(sm_id, item?.influencer_id)}>
                {filterData.platfrom === 'instagram' ? item?.username : item?.title}
              </Link>
            </strong>
            <Link to="javascript:void(0);" className="ms-auto" style={{ color: 'black' }} onClick={() => handleProfileView(sm_id, item?.influencer_id)}>
              <Launch fontSize="small" sx={{ height: 'auto' }} />
            </Link>
          </div>
        </div>
        <p>
          <Link to="javascript:void(0);" style={{ textDecoration: 'none', color: 'black' }} onClick={() => handleProfileView(sm_id, item?.influencer_id)}>
            {filterData.platfrom === 'instagram' ? item?.insta_name : item?.title}
          </Link>
        </p>
      </li>
    );
  };

  const handleDeleteSelectedInfluencer = (id: number) => {
    const updatedSelectedInfluencer = selectedInfluencer.filter((infl: any) => {
      return filterData.platfrom === "instagram" ? infl?.ig_id !== id : infl?.yt_id !== id;
    });
    setSelectedInfluencer(updatedSelectedInfluencer);
  };

  /**
  * handle compare
  */
  const handleCompareCheck = async () => {
    let url = ''
    const inflIdList =
      selectedInfluencer &&
      selectedInfluencer.map((inflId: any) => {
        return (filterData.platfrom === 'instagram') ? inflId?.ig_id : inflId?.yt_id;
      });
    const queryParameters = new URLSearchParams();

    inflIdList.forEach((id: any) => {
      queryParameters.append((filterData.platfrom === 'instagram') ? "instagramIds" : "youtubeIds", id);
    });

    if (filterData.platfrom === 'instagram'){
      url = `/brand/compare-ig?${queryParameters.toString()}`
    }else {
      url = `/brand/compare-yt?${queryParameters.toString()}`
    }
    setCompareUrl(url);

    const response = await discoverService.canCompareProfile({
      user_id: props.UserReducer.user_id,
      account_id: props.UserReducer.account_id,
      brand_id: props.UserReducer.brand_id,
      influencer_id: props.UserReducer.influencer_id,
      smIds: inflIdList
    })
    if (response.data.status && (!response.data.basic_user || response.data.basic_user && !response.data.show_compare_limit_popup)) {
      handleCompare(url);
    }
    else if (response.data.status && (response.data.basic_user && response.data.show_compare_limit_popup)) {
      setProfileCompareCount(response.data.profile_compared_count);
      setProfileCompareLimit(response.data.compare_influencer_limit);
      setProfileCompareLimitPopup(true);
    }
    else if (!response.data.status && response.data.message.includes('upgrade')) {
      // localService.toastify(response.data.message, 'info', 1000);
      handleOpenUpgradeBrandModal(true);
    }
    else if (!response.data.status) {
      // localService.toastify(response.data.message, 'error', 1000);
      console.error(`error:: ${response.data.message}`)
    }
  };

  const handleCompare = async (url: string) => {
    handleCloseProfileCompareLimitModal();
    window.open(url, "_blank");
  }

  async function onAddToList(data: any) {
    setSelectedInfluencer(data);
  }

  const handleAddToListClose = () => {
    setShowPop(false);
    setSelectedInfluencer([]);
  }

  const onChangeSetFilterData = (e: any) => {
    if (locationHook.state) {

    } else {
      setSearchList([]);
      // Reset searchKeyword to empty string when changing platform
      setSearchKeyword('');
      setClearFilter(!clearFilter);
      setFilterData(({
        ...intialTableDataFilters,
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        brand_id: props.UserReducer.brand_id,
        influencer_id: props.UserReducer.influencer_id,
        user_role_id: props.UserReducer.user_role_id,
        platfrom: e.target.value,
        readOnly: [1]
      }));
      setIsModified(false)
    }
  };

  /**
  * handle the search click event
  */
  async function searchForm(event: FormEvent) {
    event.preventDefault();
    setSearchToggle(false);
    setFocModalSearchMethod('handleSearchButton');
    // Get search count and check if allowed to search
    const response = await discoverService.canFilterSearch({
      user_id: props.UserReducer.user_id,
      account_id: props.UserReducer.account_id,
      brand_id: props.UserReducer.brand_id,
      influencer_id: props.UserReducer.influencer_id
    })

    setInsertHistory(true);
    // handleSearchButton();
    if (response.data.status && (!response.data.basic_user || response.data.basic_user && !response.data.show_search_limit_popup)) {
      // handleSearchButton();
      if (searchKeyword.length > 3) {
        setClearFilter(!clearFilter);
        if (searchKeyword === filterData.searchKeyword[0]) {
          callFillTableData();
        } else {
          handleSearchButton();
        }
      }
    }
    else if (response?.data?.status && response?.data?.basic_user && response?.data?.show_search_limit_popup) {
      setSearchCount(response?.data?.search_count);
      setSearchLimit(response?.data?.search_limit);
      setSearchLimitPopup(true);
    }
    else if (!response.data.status && response.data.message.includes('upgrade')) {
      // localService.toastify(response.data.message, 'info', 1000);
      handleOpenUpgradeBrandModal(true);
    }
    else if (!response.data.status) {
      localService.toastify(response.data.message, 'error', 1000);
    }
  }
  
 const handleAddToListShow = () => {
    setShowPop((prevState:any) => !prevState); 
  };

  useEffect(() => {
    if (selectedInfluencer.length > 0 || addInfluencerDefaultSelectList ) {
      document.body.classList.add("add-to-list-active");
    } else {
      document.body.classList.remove("add-to-list-active");
    }
  }, [selectedInfluencer, addInfluencerDefaultSelectList]); 

  useEffect(() => {
    const bodyElement = document.getElementsByTagName('body')[0];
    if (bodyElement) {
      if (showPop) {
        bodyElement.classList.add("overflow-hidden", "overlay");
      } else {
        bodyElement.classList.remove("overflow-hidden", "overlay");
      }
    } else {
      console.error("Body element not found!");
    }
  }, [showPop]);

  useEffect(() => {
    setShowPop(false);
  }, [])

  //check if click outside the particular element
  const useClickOutside = (handler: () => void): RefObject<HTMLDivElement> => {
    const domNode = useRef<HTMLDivElement>(null);

    useEffect(() => {
      const eventHandler = (event: MouseEvent) => {
        if (domNode.current && !domNode.current.contains(event.target as Node)) {
          handler();
        }
      };

      document.addEventListener('mousedown', eventHandler);

      return () => {
        document.removeEventListener('mousedown', eventHandler);
      };
    }, [handler]);

    return domNode;
  };

  const closeSearchSuggestion = () => {
    setSearchList([]); // Clear the search list to close the dropdown
  };

  const search_suggestion_div = useClickOutside(closeSearchSuggestion);

  // FOC functions and logic
  // set profile view limit Modal and functions
  const handleOpenProfileViewLimitModal = () => setProfileViewLimitPopup(true);
  function handleCloseProfileViewLimitModal() {
    setProfileViewLimitPopup(false);
  }

  // set profile compare limit Modal and functions
  const handleOpenProfileCompareLimitModal = () => setProfileCompareLimitPopup(true);
  function handleCloseProfileCompareLimitModal() {
    setProfileCompareLimitPopup(false);
  }

  // set search limit Modal and functions
  const handleOpenSearchLimitModal = () => setSearchLimitPopup(true);
  function handleCloseSearchLimitModal() {
    setSearchLimitPopup(false);
  }

  // set upgrade brand Modal variables and usestate
  const handleOpenUpgradeBrandModal = (value: boolean) => {
    setopenUpgradeBrandModal(value)
  };
  function handleCloseUpradeBrandModal() {
    setopenUpgradeBrandModal(false);
  }

  // To handle profile view
  async function handleProfileView(sm_id: number, influencerId: number) {
    try {
      loading(true);
      setSelectedSmId(sm_id);
      setSelectedInfluencerId(influencerId);

      const response = await discoverService.canViewProfile({
        user_id: props.UserReducer.user_id,
        account_id: props.UserReducer.account_id,
        brand_id: props.UserReducer.brand_id,
        influencer_id: props.UserReducer.influencer_id,
        sm_id: sm_id
      })
      if (response.data.status && (!response.data.basic_user || response.data.basic_user && !response.data.show_profile_limit_popup)) {
        handleNavigate(sm_id, influencerId);
      }
      else if (response?.data?.status && response?.data?.basic_user && response?.data?.show_profile_limit_popup) {
        setProfileViewCount(response?.data?.profile_view_count);
        setProfileViewLimit(response?.data?.profile_view_limit);
        setProfileViewLimitPopup(true);
      }
      // else if (response.data.status && response.data.basic_user ){
      //   setProfileViewLimitPopup(true);
      // }
      else if (!response.data.status && response.data.message.includes('upgrade')) {
        // localService.toastify(response.data.message, 'info', 1000);
        handleOpenUpgradeBrandModal(true);
      }
      else if (!response.data.status) {
        // localService.toastify(response.data.message, 'error', 1000);
        console.error(`error:: ${response.data.message}`)
      }
    } catch (error) {
      console.error(`handleProfileView error :: ${JSON.stringify(error)}`);
    } finally {
      loading(false);
    }
  };
  useBodyClass('page-btm-spc-exact');

  // To handle profile view
  async function handleFocProfileView() {
    try {
      // props.loading(true);
      handleCloseProfileViewLimitModal();
      handleNavigate(selectedSmId, selectedInfluencerId);
    } catch (error) {
      console.error(`handleFocProfileView error :: ${JSON.stringify(error)}`);
    } finally {
      // props.loading(false);
    }
  };

  // const handleSearchButton = async (insert_history: boolean = false) => {
  //}
  const handleSearchButton = async () => {
    setSearchLimitPopup(false);
    // setInsertHistory(true);
    // let insertHistory = insert_history;
    // const insert_history = true;
    setFilterData({
      ...intialTableDataFilters,
      user_id: props.UserReducer.user_id,
      account_id: props.UserReducer.account_id,
      brand_id: props.UserReducer.brand_id,
      influencer_id: props.UserReducer.influencer_id,
      user_role_id: props.UserReducer.user_role_id,
      searchKeyword: [searchKeyword],
      platfrom: filterData.platfrom
    });
  }

  const handleApply = async () => {
    setSearchLimitPopup(false);
    // setInsertHistory(true);
    // let insertHistory = insert_history;
    // const insert_history = true;
    // Applyy
    setSearchKeyword('');
    setFilterData({ ...filterData, searchKeyword: [], platfrom: filterData.platfrom });
  }

  async function advanceFilterForm() {
    loading(true);
    setClearSearch(false)
    // Set method to usestate to call in common ig and yt foc popup.
    setFocModalSearchMethod('handleApply');
    // Get search count and check if allowed to search
    const response = await discoverService.canFilterSearch({
      user_id: props.UserReducer.user_id,
      account_id: props.UserReducer.account_id,
      brand_id: props.UserReducer.brand_id,
      influencer_id: props.UserReducer.influencer_id
    })

    setInsertHistory(true);
    // handleApply();
    if (response.data.status && (!response.data.basic_user || response.data.basic_user && !response.data.show_search_limit_popup)) {
      handleApply();
    }
    else if (response?.data?.status && response?.data?.basic_user && response?.data?.show_search_limit_popup) {
      setSearchCount(response?.data?.search_count);
      setSearchLimit(response?.data?.search_limit);
      setSearchLimitPopup(true);
    }
    else if (!response.data.status && response.data.message.includes('upgrade')) {
      localService.toastify(response.data.message, 'info', 1000);
      handleOpenUpgradeBrandModal(true);
    }
    else if (!response.data.status) {
      localService.toastify(response.data.message, 'error', 1000);
    }
    loading(false);
  }

  // End of FOC functions and logic
 const clearSearchResult =()=>{
  setSearchKeyword("");
  const icon:any = document.getElementById("close-icon-discover");
      if (icon) {
        icon.classList.add('d-transparent');
      }
  

 }
 const handleBlur = (e:any) => {
  const icon:any = document.getElementById("close-icon-discover");
    if (icon) {
        icon.classList.add('d-transparent');
    }
  }

const handleFocus = (e: any) => {
  const icon:any = document.getElementById("close-icon-discover");
  if (icon && searchKeyword.length >0 ) {
    icon.classList.remove('d-transparent');
  }
}

const handleCancel = () => {
  // Reset filters to initial state without reloading data
  // Reset filters to their initial state
  setFilterData({
    ...intialTableDataFilters,
    user_id: props.UserReducer.user_id,
    account_id: props.UserReducer.account_id,
    brand_id: props.UserReducer.brand_id,
    influencer_id: props.UserReducer.influencer_id,
    user_role_id: props.UserReducer.user_role_id,
    platfrom: locationHook.state ? locationHook.state.list_type.toLocaleLowerCase() : 'instagram',
  });
  setSearchKeyword('');
  setClearFilter(false);
  setIsModified(false);
};
  return (
    <Container>
      <Row>
        <Col lg={6} md={6} sm={12} xs={12} className="mt-2">
          <fieldset id="platfrom_radio_id" className="input-checkbox-box-container" onChange={(e: any) => onChangeSetFilterData(e)}>
            {Platforms.map((data, index) =>
              <Fragment key={index}>
                <input name="platfrom" value={data.value} id={`id_radio_platform_${index}`} type="radio" checked={filterData.platfrom === data.value} disabled={locationHook.state && (filterData.platfrom && filterData.platfrom !== data.value ? true : false)} />
                <label className={`input-checkbox-box ${locationHook.state ? 'disable_platform_buttons' : ''}`} htmlFor={`id_radio_platform_${index}`} >
                  {data.value === 'instagram' && <InstagramIcon1 width={20}
                  />}
                  {data.value === 'youtube' && <IconYouTube width={25} maxWidth="25px"
                  />}
                  <span>{data.label === 'Youtube' ? "YouTube" : "Instagram"}</span>
                </label>
              </Fragment>
            )}
          </fieldset>
        </Col>
        <Col lg={6} md={6} sm={12} xs={12} className="mt-2">
          <div className="search-container-content" >

            {filterData.platfrom === 'instagram' && <InstagramIcon1
              className="search-plt-type-icon icon-instagram me-2"
              width={20}
            />}
            {filterData.platfrom === 'youtube' && <IconYouTube className='search-plt-type-icon icon-youtube me-2' width={25}
            />}
            <form onSubmit={searchForm} className="d-flex discover" >
              <input
                type="search"
                placeholder="Search by name or username"
                className="form-control"
                id="discover-search-by-name"
                value={searchKeyword}
                onClick={() => setSearchToggle(true)}
                onFocus={
                  (e:any)=>{
                  handleFocus(e)
                }}
                onBlur={(e:any)=>{
                  handleBlur(e)
                }}
                onChange={handleSearchField}
                
              />

              <button
                type="submit"
                className="btn d-flex btn-search"
                id="search_button"
              >
                <Search />
              </button>
            </form>
             <button
                type="button"
                className="btn close-button d-transparent"
                id= "close-icon-discover"
                onClick={clearSearchResult}
                style={{
                  position: "absolute",
                  right: "50px",
                  top: "50%",
                  transform: "translateY(-50%)",
                  background: "none",
                  border: "none",
                  cursor: "pointer",
                }}
              >               
               <Close />
              </button>
            


            {
              searchList.length > 0 && searchToggle && (
                <div className="search-suggestion" ref={search_suggestion_div}>
                  <ul>
                    {searchList.slice(0, 12)?.map((item: any) => (renderProfileListItem(item)))}
                  </ul>
                </div>
              )
            }
          </div>
        </Col>
      </Row>
      {curatedLists.length > 0 &&
        <Row>
          <Col>
            <HeadingStyleSecondary
              title="Top Curated Lists"
              className="d-block"
            />
          </Col>
        </Row>
      }
      {curatedLists.length > 0 &&
        <Row>
          <Col>
            <Suspense >
              <CuratedList
                data={curatedLists}
                platform={filterData.platfrom}
                UserReducer={props.UserReducer}
                getSubscriptionDetail={props.getSubscriptionDetail}
                onAddToList={onAddToList}
                addInfluencerDefaultSelectList={addInfluencerDefaultSelectList} />
            </Suspense>
          </Col>
        </Row>
      }

      {/* WRITE FILTERS IN COMPONENT AND ADD HERE */}
      <CustomCollapse label={"Advanced Search"}>
        <Suspense>
          <InfluencerFilters
            clearData={clearFilter}
            setFilterData={(event) => setFilterData(prev => ({ ...prev, ...event }))}
            platform={filterData.platfrom as ('instagram' | 'youtube')}
            isBasicUser={props?.getSubscriptionDetail?.isBasicUser}
            handleOpenUpgradeBrandModal={handleOpenUpgradeBrandModal}
          />
        </Suspense>
        <br />
        <Suspense>
          <AudienceFilters
            clearData={clearFilter}
            platform={filterData.platfrom as ('instagram' | 'youtube')}
            setFilterData={(event) => {
              setFilterData(prev => ({ ...prev, ...event }))
            }}
            isBasicUser={props?.getSubscriptionDetail?.isBasicUser}
            handleOpenUpgradeBrandModal={handleOpenUpgradeBrandModal}
          />
        </Suspense>
        <Row className='d-flex justify-content-end mt-4'>
          <Col className="col-auto mb-3 text-end gap-3">
            <button type="button" className="btn btn-outline-primary"
              disabled={!isModified}
              
             onClick={() => {
              setClearFilter(!clearFilter)
              setSearchKeyword("");
              setClearSearch(true)
              // setClearSearch(!clearSearch);
            }}
            // onClick={handleCancel}
            >
              
              Cancel</button>
            <button type="button" className="btn btn-primary ms-2" onClick={() => {
              advanceFilterForm()
            }} >Show Results ({filterCount ? filterCount:"0"})</button>
            
          </Col>
        </Row>
      </CustomCollapse>
      <Row className='mb-2 d-flex'>
        <Col className='d-flex justify-content-between align-items-center'>
        <span><strong>{totalInfluencerCounts}&nbsp;Influencers &nbsp;&nbsp;</strong> </span>
        {/* {searchKeyword.length > 0 && */}
        {/* clearSearch ? "cursor-pointer":"p-none  disabled opacity-75" */}
          <button disabled={clearSearch} className="btn btn-sm btn-outline-primary" onClick={() => {
          setClearSearch(true);
          setSearchKeyword("");
          fillTableData();
          setFilterData((prev:any)=>({
            ...intialTableDataFilters, // Reset all filter data
            user_id: props.UserReducer.user_id,
            account_id: props.UserReducer.account_id,
            brand_id: props.UserReducer.brand_id,
            influencer_id: props.UserReducer.influencer_id,
            user_role_id: props.UserReducer.user_role_id,
            platfrom: prev.platfrom
          }));
          setClearFilter(true);
        }} >Clear Search</button>
        {/* } */}
        </Col>
      </Row>
      <Row>
        <Col>
          <fieldset className="influencer_type_cla sidebar-nav" onChange={(e: any) => {
            if (infCounts[parseInt(e.target.value)] !== 0) {
              setActiveTabBoolean(false);
              setActiveTab(parseInt(e.target.value));
              setFilterData(prev => ({ ...prev, page: 1 }))
            }
          }}>
            {InfluencerTypes?.map((data, index) =>
              <Fragment key={index}>
                <input name="influencer_type" value={data.id} id={`id_radio_discover_${index}`} type="radio" checked={activeTab === data.id} />
                <label htmlFor={`id_radio_discover_${index}`} className={`sidebar-nav-link ${infCounts[data.id] === 0 ? "disabled" : ""}`}>
                  <strong className='influencer_type_name'>{data.influencer_type}

                  </strong>
                  <span className='influencer_type_range'>
                    ({methods.formatNumber(data.min).includes('M+') ? methods.formatNumber(data.max) : methods.formatNumber(data.min) + '-' + methods.formatNumber(data.max)})
                  </span>
                  <span className='influencer_type_count'>{infCounts[data.id]}&nbsp;Influencers</span>
                 

                </label>
              </Fragment>
            )}
          </fieldset>

          <CommonTableComponent
            platformType={filterData?.platfrom}
            data={tableDatas}
            loading={isLoading}
            selectedInfluencer={selectedInfluencer}
            handleCheckbox={handleCheckbox}
            handleProfileView={handleProfileView}
            getSubscriptionDetail={props.getSubscriptionDetail}
            handleNavigate={handleNavigate}
            total_records={infCounts[activeTab]}
            currentPage={filterData.page - 1}
            rowPerPage={filterData.view_records}
            setCurrentPage={(pageNumber) => setFilterData(prev => {
              return ({ ...prev, page: pageNumber + 1 })
            })}
            setRowPerPage={(viewRecords) => setFilterData(prev => {
              return ({ ...prev, view_records: viewRecords })
            })}
          />

        </Col>
      </Row>
      <Row>
        {selectedInfluencer.length > 0 && addInfluencerDefaultSelectList === null && <div className="col compare-toolbar">
          <div className="tools">
            <Tabs
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              aria-label="scrollable force tabs example"
              className="image-section"
            >
              {selectedInfluencer?.map((data: any, index: number) => {
                return (
                  <div className="compare-img">
                    <img
                      key={index}
                      src={
                        (data.gcp_profile_image_url || data.profile_image_url)
                            ? (data.gcp_profile_image_url ? data.gcp_profile_image_url : data.profile_image_url)
                            : DefaultUserProfile.influencer_unknown_default
                      }
                      alt="profile"
                    />
                    <span
                      onClick={() =>
                        handleDeleteSelectedInfluencer(filterData.platfrom === 'instagram' ? data.ig_id : data.yt_id)
                      }
                      className="remove-infl"
                    >
                      <Delete />
                    </span>
                  </div>
                );
              })}
            </Tabs>
            {showPop &&
              <div className='popper-popup discover-add-to-list'>
                <AddToList platform={filterData.platfrom} UserReducer={props.UserReducer} selectedInfluencer={selectedInfluencer} showAddToListPopup={showPop} handleAddToListClose={handleAddToListClose} handleOpenUpgradeBrandModal={handleOpenUpgradeBrandModal} />
              </div>
            }
            <div className="button-section position-relative">
              {selectedInfluencer.length <= 3 && selectedInfluencer.length > 1 && (
                <Button
                  variant="contained"
                  className="btn btn-primary"
                  onClick={() => handleCompareCheck()}
                >
                  Compare
                </Button>
              )}


              <Button
                variant="contained"
                onClick={handleAddToListShow}
                className="btn btn-primary"
              >
                Add to List
              </Button>


            </div>
          </div>
        </div>}
      </Row>

      {
        addInfluencerDefaultSelectList &&
        <SaveFromList
          UserReducer={props.UserReducer}
          selectedInfluencer={selectedInfluencer}
          addInfluencerDefaultSelectList={addInfluencerDefaultSelectList}
          handleOpenUpgradeBrandModal={handleOpenUpgradeBrandModal}
        />
      }
      {/* Upgrade brand popup */}
      {openUpgradeBrandModal && (
        <UpgradeBrandPopUp
          openUpgradeBrandModal={openUpgradeBrandModal}
          onClose={() => handleCloseUpradeBrandModal()}
          popupMinWidth='630px'
        >
        </UpgradeBrandPopUp>
      )}
      {/* FOC Search limit popup */}
      {
        searchLimitPopup && (
          <CommonPopUp
            modalTitle={searchLimitModalTitle}
            // modalContent={modalContent}
            openModal={searchLimitPopup}
            onClose={() => handleCloseSearchLimitModal()}
            popupMinWidth='630px'
            className="modal-medium mx-auto"
          >
            {
              (
                <div className="justify-content-between align-items-center">
                  <div className="">
                    <p>
                      This is your <strong>{searchCount}/{searchLimit}</strong> search. If you continue, you will be left with <strong>{searchLimit - searchCount} </strong>searches for this month.
                    </p>

                    <p className="inline">Do you wish to continue?</p>
                  </div>

                  <div className='popup-footer' style={{ padding: '24px 2px' }}>
                    <div className='row'>
                      <div className='col text-end' style={{ padding: '0px 44px' }}>
                        <div className="d-flex justify-content-end ml-auto">
                          <Button
                            className="btn btn-outline-primary btn-mui"
                            onClick={() => handleCloseSearchLimitModal()}
                            variant="contained"
                          >
                            No
                          </Button>
                          {focModalSearchMethod &&
                            <Button
                              className="btn btn-primary btn-mui  ms-2"
                              onClick={async () => {
                                if (focModalSearchMethod === 'handleSearchButton') {
                                  // await handleSearchButton(true);
                                  await handleSearchButton();
                                } else {
                                  await handleApply();
                                }
                              }}
                              variant="outlined"
                            >
                              Yes
                            </Button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </CommonPopUp>
        )
      }
      {/* FOC Profile view limit popup */}
      {
        profileViewLimitPopup && (
          <ProfileViewRestriction
            openModal={profileViewLimitPopup}
            onClose={() => handleCloseProfileViewLimitModal()}
            profileViewCount={profileViewCount}
            profileViewLimit={profileViewLimit}
            handleFocProfileView={() => handleFocProfileView()}
          />
        )
      }

      {/* FOC Compare limit popup */}
      {
        profileCompareLimitPopup && (
          <CommonPopUp
            modalTitle={profileCompareModalTitle}
            // modalContent={modalContent}
            openModal={profileCompareLimitPopup}
            onClose={() => handleCloseProfileCompareLimitModal()}
            popupMinWidth='630px'
            className="modal-medium mx-auto"
          >
            {
              (
                <div className="justify-content-between align-items-center">
                  <div className="">
                    <p>
                      You have <strong>{profileCompareLimit}</strong> profiles to compare under FOC model. After comparing these <strong>{selectedInfluencer.length}</strong> profiles, you will be left with <strong>{(profileCompareLimit - profileCompareCount)}</strong> {(profileCompareLimit - profileCompareCount) > 1 ? 'profiles' : 'profile'} to compare. 
                    </p>

                    <p className="inline">Do you wish to continue?</p>
                  </div>

                  <div className='popup-footer' style={{ padding: '24px 2px' }}>
                    <div className='row'>
                      <div className='col text-end' style={{ padding: '0px 44px' }}>
                        <div className="d-flex justify-content-end ml-auto">
                          <Button
                            className="btn btn-outline-primary btn-mui"
                            onClick={() => handleCloseProfileCompareLimitModal()}
                            variant="contained"
                          >
                            No
                          </Button>
                          <Button
                            className="btn btn-primary btn-mui  ms-2"
                            onClick={async () => {
                              await handleCompare(compareUrl);
                            }}
                            variant="outlined"
                          >
                            Yes
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </CommonPopUp>
        )
      }
    </Container >
  )
}

const mapStateToProps = (state: any) => {
  return {
    UserReducer: state.UserReducer.UserData,
    getSubscriptionDetail: state.Subscription.subscription_detail,
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    loading: (data: any) => dispatch(Loading(data)),
  };
};

const connectToRedux = connect(mapStateToProps, mapDispatchToProps)(Discover);

export { connectToRedux as Discover };
