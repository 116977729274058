import { Grid } from '@mui/material'
import React, { useEffect } from 'react'
import { DefaultUserProfile } from '../../../../../config/config';
import { Link } from 'react-router-dom';

type Props = {
  sortedData: [];
  selectCampaign?: any;
  sampleData?:boolean;
  platform?:{
    campaign_platform:any
  };
  campaign_platform?:any;
  campaignPlatform?:any;
  // platform?:any;
  
}

export const SampleMostEngagingPosts = (props: Props) => {
  return (
    <div className="Most-Engaging-Posts">
      <Grid container spacing={2}>
        {props?.sortedData.length > 0 && props?.sortedData.map((item: any) => (
          <Grid item xs={12} md={4} lg={2}>
            
            <div className="grid-div align-items-start w-100">
              <>
              {(props?.campaignPlatform?.campaign_platform === 'Instagram' || props?.campaignPlatform === 'Instagram') &&
                <><Link className='w-100' target='_blank' to="#">
                  <img src={item?.gcp_display_url && item?.gcp_display_url ? item?.gcp_display_url : DefaultUserProfile.influencer_unknown_default}
                  alt={item?.username}
                  className="grid-image" />
                </Link>
               
                </>
              }</>
              {props?.campaignPlatform?.campaign_platform === 'Youtube' || props?.campaignPlatform === 'Youtube' &&
                <Link target='_blank' to="#">
                  <img src={item?.thumbnails ? item?.thumbnails : DefaultUserProfile.influencer_unknown_default} alt={item?.username} className="grid-image" />
                </Link>
              }
              {/* <span>{(props?.campaignPlatform?.campaign_platform === 'Instagram' || props?.campaignPlatform === 'Instagram') ? item?.username : item?.title}</span> */}
              {(props?.campaignPlatform?.campaign_platform === 'Instagram' || props?.campaignPlatform === 'Instagram') &&
                <Link target='_blank' className='btn btn-text-link mb-1' to={`https://www.instagram.com/${item?.username}`}>
                  {item?.username}
                </Link>
              }
              {props?.campaignPlatform?.campaign_platform === 'Youtube' || props?.campaignPlatform === 'Youtube' &&
                <Link target='_blank' className='btn btn-text-link mb-1' to={`https://www.youtube.com/${item?.custom_url}`}>
                  {item?.custom_url}
                </Link>
              }
              {(props?.campaignPlatform?.campaign_platform === 'Instagram' || props?.campaignPlatform === 'Instagram') &&
                <span>ER  {item?.er}%</span>
              }
              {props?.campaignPlatform?.campaign_platform === 'Youtube' || props?.campaignPlatform === 'Youtube' &&
                <Link target='_blank' className='btn btn-text-link mb-1' to="#">
                  <span className='most-engaging-post-title'>{item?.title}</span>
                </Link>
              }
              {props?.campaignPlatform?.campaign_platform === 'Youtube' || props?.campaignPlatform === 'Youtube' &&
                <span>ER  {item?.er}%</span>
              }
            </div>
            
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
