// import React, { useEffect } from "react";

// interface InstagramEmbedProps {
//   embedUrl: string; // The Instagram post URL to embed
// }

// const InstagramEmbed: React.FC<InstagramEmbedProps> = ({ embedUrl }) => {
//   useEffect(() => {
//     // Check if the script already exists to avoid duplicate appends
//     if (!document.querySelector('script[src="//www.instagram.com/embed.js"]')) {
//       const script = document.createElement("script");
//       script.src = "//www.instagram.com/embed.js";
//       script.async = true;
//       document.body.appendChild(script);
//     }

//     // Process Instagram embeds after ensuring the script is loaded
//     const processInstagramEmbeds = () => {
//       if (window.instgrm?.Embeds) {
//         window.instgrm.Embeds.process();
//       }
//     };

//     // Ensure embed processing runs after script load
//     if (window.instgrm?.Embeds) {
//       processInstagramEmbeds();
//     } else {
//       const script = document.querySelector(
//         'script[src="//www.instagram.com/embed.js"]'
//       );
//       script?.addEventListener("load", processInstagramEmbeds);
//     }

//     return () => {
//       // Cleanup listeners (if any) to prevent memory leaks
//       const script = document.querySelector(
//         'script[src="//www.instagram.com/embed.js"]'
//       );
//       script?.removeEventListener("load", processInstagramEmbeds);
//     };
//   }, [embedUrl]);

//   return (
//     <blockquote
//       className="instagram-media"
//       data-instgrm-permalink={embedUrl}
//       data-instgrm-version="14"
//       style={{ margin: "auto" }}
//     ></blockquote>
//   );
// };

// export default InstagramEmbed;


import React, { useEffect } from "react";

interface InstagramEmbedProps {
  embedUrl: string;
}

const InstagramEmbed: React.FC<InstagramEmbedProps> = ({ embedUrl }) => {
  useEffect(() => {
    let isComponentMounted = true;
    let script: HTMLScriptElement | null = null;

    // Append Instagram embed script if not already present
    if (!document.querySelector('script[src="//www.instagram.com/embed.js"]')) {
      script = document.createElement("script");
      script.src = "//www.instagram.com/embed.js";
      script.async = true;
      document.body.appendChild(script);
    }

    const processInstagramEmbeds = () => {
      if (isComponentMounted && window.instgrm?.Embeds) {
        window.instgrm.Embeds.process();
      }
    };

    // Ensure embed processing runs after the script is loaded
    if (window.instgrm?.Embeds) {
      processInstagramEmbeds();
    } else {
      script?.addEventListener("load", processInstagramEmbeds);
    }

    return () => {
      isComponentMounted = false; // Prevent processing after unmount
      script?.removeEventListener("load", processInstagramEmbeds);
    };
  }, [embedUrl]);

  return (
    <blockquote
      className="instagram-media"
      data-instgrm-permalink={embedUrl}
      data-instgrm-version="14"
      style={{ margin: "auto" }}
    ></blockquote>
  );
};

export default InstagramEmbed;
